.baslik {
  font-size: 3.3rem !important;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 30px;
}

.entry-news-card {
  line-height: 1.4;
  color: #757575;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 #666666;
  border-radius: 20px;
}
.entry-news-card {
  border-radius: 20px 20px 0 0;
}

.containerx {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  margin-bottom: 50px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.entry-news-card {
  line-height: 1.4;
  color: #757575;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 #666666;
}

.entry-news-card__frame {
  position: relative;
}

.entry-news-card__picture {
  position: relative;

  &::before {
    content: "";
    display: block;
    height: 0;
    padding-top: calc(9 / 16 * 100%);
  }

  a {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.entry-news-card__content {
  padding: 15px;
}

.entry-news-card__category {
  margin-bottom: 6px;
  font-size: 14px;
}

.entry-news-card__category-link {
  text-decoration: none;
  color: #009688;

  &:hover,
  &:focus {
    color: #3d5afe;
  }
}

.entry-news-card__title {
  margin: 0;
  margin-bottom: 6px;
  font-size: 22px;
  line-height: 1.2;

  a {
    display: block;
    text-decoration: none;
    color: #377cc7;
    transition: 0.25s;

    &:hover,
    &:focus {
      color: #3d5afe;
    }
  }
}

.entry-news-card__meta {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 6px;
  padding: 0;
  list-style: none;
}

.entry-news-card__meta-item {
  margin-right: 15px;
}

.entry-news-card__date {
  font-size: 12px;
}

.entry-news-card__author {
  font-weight: 700;
  font-size: 14px;
}

.entry-news-card__text {
  margin-bottom: 6px;
  font-size: 14px;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.entry-news-card__conversation-count {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}

.entry-news-card__conversation-count-link {
  text-decoration: none;
  color: #757575;

  &:hover,
  &:focus {
    color: #3d5afe;
  }
}

.news-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 15px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.containerx ul {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .news-cards {
    grid-template-columns: 1fr;
    margin-left: 10px;
    margin-right: 10px;
  }
  .containerx ul {
    display: flex;
    flex-direction: column;
  }
  .baslik {
    font-size: 2rem !important;
    font-weight: bold;
    color: #fcfcfc;
    text-align: center;
    margin-top: -3px;
    margin-bottom: 30px;
  }
}

/* body {
  background: url('cnc.jpg') no-repeat center center;
  background-size: cover;
  
} */

.entry-news-card {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* veya */

.entry-news-card {
  position: relative;
  overflow: hidden;
  border: 4px solid #ddd; /* Çerçeve rengini ve kalınlığını ayarlayabilirsin */
  border-radius: 20px; /* Bu satırı ekle */
}

.press-page {
  margin-bottom: -50px;
  background: url("cnc.jpg") no-repeat center center;
  background-size: cover;
}

.entry-news-card__frame {
  position: relative;
  overflow: hidden;
  border-radius: 20px; /* Bu satırı ekle */

  /* Eğer shadow kullanmak istiyorsanız aşağıdaki satırı kullanabilirsiniz */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.entry-news-card__picture {
  position: relative;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}

.entry-news-card__picture::before {
  content: "";
  display: block;
  height: 0;
  padding-top: calc(9 / 16 * 100%);
}

.press-page a {
  display: block;
}

.press-page img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
