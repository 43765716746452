.container-detail {
  background-color: #f4f8fc;
  margin-top: 193px;
  margin-bottom: 140px;
}
/* .swiper {
  width: 100%;
  height: 100%;
} */
.product-images-slider2 {
  height: 60%;
  width: 50%;
}
.product-images-slider {
  box-sizing: border-box;
  margin-top: 10px;
  /* padding: 10px 0; */
  height: 20%;
  /* width: 50%; */
}
.product-images-slider .swiper-slide {
  width: 25%;
  height: 100%;
  /* opacity: 0.4; */
}
.swiper-slide {
  text-align: center;
  font-size: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#slider {
  /* height: 517px; */
  margin-top: 30px;
}
.slider {
  height: 622px;
}

.info-ilan {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 100%;
}
.info-ilan .descripton-title {
  padding: 0 0 10px 10px;
}
.product-images-slider .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-button-prev,
.swiper-button-next {
  font-size: 10px;
  /* font-weight: bold; */
  color: rgb(255, 255, 255);
}
.swiper-button-prev::after,
.swiper-button-next::after {
  /* font-size: 3px; */
  /* background-color: rgba(255, 255, 255, 0.3); */
  padding: 10px;
}
.swiper {
  width: 100%;
}

.detail-place .loc {
  padding-bottom: 20px;
  margin-top: 0;
}
#detail {
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(background.jpg);
  background-size: cover;
}

.place-detail {
  position: relative;
  color: white;
}

.place-detail > .col-md-12 {
  display: flex;
  justify-content: center;
}
.detail-top-title {
  font-weight: 600;
  text-transform: capitalize;
  color: black;
  font-size: 2rem;
  padding: 20px;
}

.detail-place {
  display: flex;
  flex-direction: column;
  grid-gap: 8px 20px;
  margin: 20px;
  align-items: start;
}
.details {
  align-items: center;
  justify-content: space-evenly;
  /* font-size: 30px; */
}
.details span {
  color: var(--price-text-color);
  font-size: 33px;
}
.description {
  color: black;
  margin-bottom: 40px;
}
.descripton-title {
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  /* margin: 10px 0 16px; */
}
.detail-place .location {
  display: flex;
  color: #64707c;
  font-weight: 500;
}
.detail-place .location i {
  margin-right: 7px;
  font-size: 23px;
  color: black;
}
.info {
  height: 380px;
  overflow: hidden;
}
/* .info .row {
  position: relative;
  float: left;
  width: 100%;
  padding: 0 8px;
} */
.info-title {
  display: flex;
  position: relative;
  width: 100%;
}

.info-title:nth-child(odd) .info-title-detail {
  background-color: #fff;
  border: none;
}
.info-title .info-title-detail:first-of-type {
  width: 45%;
}
.info-title .info-title-detail:last-of-type {
  width: 55%;
}
.info-title .info-title-detail:last-child {
  color: #223445;
  text-align: right;
}
.info-title .info-title-detail {
  float: left;
  height: 40px;
  padding: 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f4f8fc;
  border: 1px solid #edf2f6;
  border-left: 0;
  border-right: 0;
  font-size: 18px;
  line-height: 36px;
  color: #64707c;
  text-transform: capitalize;
  position: relative;
}
@media (max-width: 767px) {
  .descripton-title {
    font-size: 20px;
  }
  .info-title .info-title-detail {
    padding: 0 6px;
    font-size: 16px;
  }
  .info {
    overflow: scroll;
  }
  .container-detail {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  #slider {
    height: auto;
    margin-top: 20px;
  }
  #detail {
    background-image: none;
  }
}
@media (max-width: 431px) {
  .detail-top-title {
    font-size: 1.2rem !important;
    /* padding: 20px 5px; */
  }
  .details {
    margin-top: 30px;
    margin-bottom: 6px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .details span {
    font-size: 27px;
  }
  .slider {
    padding: 0 5px;
    height: 360px;
  }
  .info-ilan .descripton-title {
    padding: 0;
    padding-bottom: 20px;
  }
  .info-ilan {
    margin-top: 0;
  }
  .detail-place {
    margin: 0;
  }
  .detail-place .loc {
    margin-top: 40px;
  }
  .explanation {
    margin-top: 40px;
  }
  .descripton-title {
    font-weight: 500;
  }
}
