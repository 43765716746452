#contact {
  width: 100%;
  height: 100%;
  background: url("cnc.jpg") no-repeat center center;
  background-size: cover;
}

.section-header {
  margin-top: -120px !important;
  text-align: center;
  margin-bottom: 50px !important;
  margin: 0 auto;
  padding: 40px 0;
  font: 500 60px "Oswald", sans-serif;
  color: #fff;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  margin-top: -80px;
  position: relative;
  max-width: 840px;
  background-color: #000;
  border-radius: 20px;
  opacity: 0.85;
}

/* Left contact page */
.form-horizontal {
  /*float: left;*/
  max-width: 400px;
  font-family: "Lato";
  font-weight: 400;
}

.form-control,
textarea {
  margin: 3px;
  max-width: 400px;
  background-color: #ffffff;
  color: black;
  letter-spacing: 1px;
  width: 98%;
}
#submit {
  background-color: #2da088;
}

.send-button {
  margin-top: 15px;
  height: 34px;
  width: 400px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  color: white;
}

.alt-send-button {
  width: 400px;
  height: 34px;
  transition: all 0.2s ease-in-out;
}

.send-text {
  display: block;
  margin-top: 10px;
  font: 700 12px "Lato", sans-serif;
  letter-spacing: 2px;
}

/* Begin Right Contact Page */
.direct-contact-container {
  max-width: 400px;
  background-color: #000;
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  margin-left: 0px;
  padding-right: 20px;
  background-color: #000;
}

.list-item {
  line-height: 1.5;
  color: #fefefe;
  padding: 10px 0; /* Adjust padding as needed */
}

.contact-text {
  font: 300 18px "Lato", sans-serif;
  letter-spacing: 1.5px;
  color: #bbb;
}

.place {
  margin-left: 62px;
}

.phone {
  margin-left: 56px;
}

.gmail {
  margin-left: 53px;
}

.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}

/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 35px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 5px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(27, 27, 27);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #fff;
}

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

.social-media-list li:hover a {
  color: #000;
}

.copyright {
  font: 200 14px "Oswald", sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
}

/* Begin Media Queries*/
@media screen and (max-width: 850px) {
  .section-header {
    font-size: 50px;
    margin-bottom: -2px !important;
    margin-top: 0px !important;
    margin-top: -80px !important;
  }
  .contact-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 1px;
  }
  .direct-contact-container,
  .form-horizontal {
    margin: 0 auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 300px;
  }
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

@media screen and (max-width: 569px) {
  .direct-contact-container,
  .form-wrapper {
    float: none;
    margin: 0 auto;
  }
  #submit {
    width: 99%;
  }
  .form-control,
  textarea {
    margin: 0 auto;
    margin: 5px;
  }
  .mesaj {
    height: 150px;
  }

  .name,
  .email,
  textarea {
    width: 280px;
  }
  .contact-list {
    padding-left: 18px;
    padding-right: 0;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 100%;
  }
  .social-media-list {
    left: 0;
    display: flex;
  }
  .social-media-list li {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 2rem;
  }
  .social-media-list li:after {
    width: 55px;
    height: 55px;
    line-height: 55px;
  }
}

@media screen and (max-width: 410px) {
  .send-button {
    width: 99%;
  }
  .contact-text {
    font-size: 16px !important;
  }
}

.contact-text {
  color: #bbb;
  font-size: 18px;
  font-weight: 300;
  margin-left: 10px;
  margin-top: 10px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
