@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

#foot * {
  margin: 0;
  padding: 0;
}

#foot body {
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
}

#foot .footer {
  background-color: #151515;
  padding: 80px 0;
}

#foot .container {
  max-width: 1170px;
  margin: auto;
}

#foot .row {
  display: flex;
  flex-wrap: wrap;
}

#foot ul {
  list-style: none;
}

#foot .footer-col {
  width: 25%;
  padding: 0 15px;
}

#foot .footer-col h4 {
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

#foot .footer-col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #e91e63;
  width: 50px;
  height: 2px;
}

#foot .footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

#foot .footer-col ul li a {
  color: #ddd;
  display: block;
  font-size: 1rem;
  font-weight: 300;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s ease;
}

#foot .footer-col ul li a:hover {
  color: #fff;
  padding-left: 7px;
}

#foot .footer-col .social-links a {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin: 0 10px 10px 0;
  line-height: 40px;
  transition: all 0.5s ease;
}

#foot .footer-col .social-links a:hover {
  color: #151515;
  background-color: #fff;
}

@media (max-width: 767px) {
  #foot .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
  #foot li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #foot .fa-envelope {
    margin-right: 5px; /* İhtiyaca bağlı olarak ayarlayabilirsiniz */
  }
  
  #foot a {
    text-decoration: none;
    color: #000; /* İhtiyaca bağlı olarak ayarlayabilirsiniz */
  }
}

@media (max-width: 574px) {
  #foot .footer-col {
    width: 100%;
  }
  #foot li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #foot .fa-envelope {
    margin-right: 5px; /* İhtiyaca bağlı olarak ayarlayabilirsiniz */
  }
  
  #foot a {
    text-decoration: none;
    color: #000; /* İhtiyaca bağlı olarak ayarlayabilirsiniz */
  }
}

@media (max-width: 574px) {
  #foot .footer-col {
    text-align: center;
  }

  #foot .footer-col h4::before {
    left: 50%;
    transform: translateX(-50%);
  }

  #foot .footer-col ul {
    text-align: center;
  }
  #foot li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #foot .fa-envelope {
    margin-right: 5px; /* İhtiyaca bağlı olarak ayarlayabilirsiniz */
  }
  
  #foot a {
    text-decoration: none;
    color: #000; /* İhtiyaca bağlı olarak ayarlayabilirsiniz */
  }
}



#foot li {
  display: flex;
  align-items: center;
}

#foot .fa-envelope {
  margin-right: 5px; /* İhtiyaca bağlı olarak ayarlayabilirsiniz */
}

#foot a {
  text-decoration: none;
  color: #000; /* İhtiyaca bağlı olarak ayarlayabilirsiniz */
}

#mailt {
  text-transform: lowercase !important;; 
}

li i {
color: #ddd !important;}