.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease;
  backdrop-filter: blur(5px);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: rgba(50, 50, 93, 0.23) 30px 30px 20px;
  margin-top: 0px;
  padding: 0;
}
.navbar a {
  text-decoration: none;
}
.logo img {
  width: auto;
  height: 100px;
  margin-right: 20px;
  border-radius: 30px;
}
.logo {
  margin-right: 177px !important;
  margin-left: 76px;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 83%;
  align-items: center;
}
.nav-container {
  width: 100%;
  margin: auto;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.nav-links li {
  color: #fff !important;
  font-size: 22px;
  padding: 0 4px;
  margin: 0 2px;
  cursor: pointer;
  text-transform: capitalize;
  list-style: none;
}

.navbar.scrolled li a {
  color: #50a596 !important;
  border-radius: 50px;
}
.navbar.scrolled {
  background-color: white;
  box-shadow: none;
}

.navbar li a {
  /* color: #50a596 !important;
  font-size: 19px;
  margin: 0 15px;
  padding: 5px; */
  color: #50a596 !important;
  font-size: 20px;
  margin: 0 15px;
  border-radius: 50px;
  font-weight: 800;
  color: white !important;
  padding: 8px;
}
.navbar li a:hover {
  color: rgb(255, 255, 255) !important;
  background-color: #50a596 !important;
  transition: 0.9s;
}
.nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #50a596;
  visibility: hidden;
  opacity: 0;
  font-size: 1.9rem;
}
@media only screen and (max-width: 991px) {
  .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #50a596;
    transition: 1s;
    transform: translateY(-100vh);
  }
  .responsive_nav {
    transform: none;
  }
  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar li a {
    color: white !important;
    font-size: 1.5rem;
  }
  .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #fff;
  }
  .logo {
    margin-left: -10px !important;
  }
  .navbar.scrolled li a {
    color: white !important;
  }
}
@media only screen and (max-width: 769px) {
  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 415px) {
  .logo {
    margin-right: 137px !important;
  }
}
@media only screen and (max-width: 431px) {
  .logo img {
    margin-top: 10px;
  }
}
