.team {
  background: url("cnc.jpg") no-repeat center center;
  background-size: cover;
}

.teamwrapper .row {
  display: flex;
  flex-wrap: wrap;
  padding: 2em 1em;
  text-align: center;
}

.teamwrapper .column {
  width: 100%;
  padding: 0.5em 0;
}

.teamwrapper h1 {
  width: 100%;
  text-align: center;
  font-size: 3.5em;
  color: white;
  font-weight: bold;
}
.teamwrapper .titleFamily {
  margin-top: 93px;
}

.teamwrapper .card {
  box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
  padding: 3.5em 1em;
  border-radius: 0.6em;
  color: #1f003b;
  cursor: pointer;
  transition: 0.1s;
  background-color: #ffffff;
}

.teamwrapper .card .img-container {
  width: 8em;
  height: 8em;
  background-color: #50a596;
  padding: 0.5em;
  border-radius: 50%;
  margin: 0 auto 2em auto;
}

.teamwrapper .card img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}

.teamwrapper .card h3 {
  font-weight: 500;
}

.teamwrapper .card p {
  font-weight: 300;
  text-transform: uppercase;
  margin: 0.5em 0 2em 0;
  letter-spacing: 2px;
}

.teamwrapper .icons {
  width: 50%;
  min-width: 180px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.teamwrapper .card a {
  text-decoration: none;
  color: #50a596;
  font-size: 1.4em;
}

.teamwrapper .card:hover {
  background: linear-gradient(#50a596, #50a596);
  color: #ffffff;
  a {
    color: black;
  }
}

.teamwrapper .card:hover .img-container {
  transform: scale(1.35);
}

@media screen and (min-width: 768px) {
  .teamwrapper section {
    padding: 1em 7em;
  }
}
@media screen and (max-width: 768px) {
  .teamwrapper .titleFamily h1 {
    margin-top: 110px !important;
    font-size: 43px !important;
  }
  .teamwrapper .titleFamily {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 992px) {
  .teamwrapper section {
    padding: 1em;
  }

  .teamwrapper .card {
    padding: 5em 1em;
  }

  .teamwrapper .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 1em;
  }
}
.teamwrapper .card2 {
  box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
  padding: 3.5em 1em;
  border-radius: 0.6em;
  color: #1f003b;
  transition: 0.1s;
  background-color: #ffffff;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.teamwrapper .card2 .img-container {
  width: 8em;
  height: 8em;
  background-color: #50a596;
  padding: 0.5em;
  border-radius: 50%;
  margin: 0 auto 2em auto;
}

.teamwrapper .card2 img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}

.teamwrapper .card2 h3 {
  font-weight: 500;
}

.teamwrapper .card2 p {
  font-weight: 300;
  text-transform: uppercase;
  margin: 0.5em 0 2em 0;
  letter-spacing: 2px;
}

.teamwrapper .card2 a {
  text-decoration: none;
  color: #50a596;
  font-size: 1.4em;
}

.teamwrapper .column2 {
  width: 100%;
  padding: 0.5em 0;
}

@media screen and (min-width: 992px) {
  .teamwrapper .column2 {
    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 1em;
  }
}
