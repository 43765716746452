.handwriting-container {
  font-family: 'Pacifico', cursive;
  font-size: 80px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.handwriting-text {
  white-space: nowrap;
}

@media (max-width: 767px) {
  .handwriting-container {
    font-size: 40px; 
    margin-top: -120px;
  }
}
