:root {
  --mainColor: #0eaaa6;
}
.listing * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
.listing ::-webkit-scrollbar {
  width: 10px;
}
.listing ::-webkit-scrollbar-track {
  background: #fff;
}
.listing ::-webkit-scrollbar-thumb {
  background: var(--mainColor);
}
.listing .listingcontainer {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  padding-top: 150px;
}
.listing .listingcontainer form {
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-block: 25px;
}
.listing .listingcontainer form input,
.listing .listingcontainer form select {
  outline: none;
  border: none;
  padding: 10px;
  width: 293px;
}
.listing .listingcontainer form select {
  width: 293px !important;
}
.listing .listingcontainer form .price {
  position: relative;
}
.listing .listingcontainer form .price input::-webkit-outer-spin-button,
.listing .listingcontainer form .price input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.listing .listingcontainer form .price input {
  width: 100%;
  padding-left: 45px;
}
.listing .listingcontainer form .price span {
  display: inline-block;
  padding: 10px 15px;
  color: #000;
  background-color: #ddd;
  position: absolute;
  top: 0;
  left: 0;
}
.listing .listingcontainer form button {
  width: 100px;
  border: none;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mainColor);
  color: #fff;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
}
#btn_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1185px) {
  .listing .listingcontainer form {
    flex-flow: column;
    gap: 20px;
  }
  /* .listing .listingcontainer form input, */
  .listing .listingcontainer form select,
  .listing .listingcontainer form .price,
  .listing .listingcontainer form button {
    width: 90%;
  }
  #btn_container button {
    width: 105px !important;
  }
}
.listing .listingcontainer .content {
  background-color: var(--mainColor);
  padding-block: 20px;
  text-align: center;
  color: #fff;
}
.listing .listingcontainer .content h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
  text-transform: capitalize;
}
.listing .listingcontainer .content p {
  font-style: italic;
}

.listing {
  background: url(cnc.jpg) no-repeat center center;

  background-size: cover !important;
}

/* globale variable */

:root {
  --bg-body: #f4f4f4;
  --bg-hot: #dd3333;
  --bg-featured: #ea723d;
  --p-text-color: #7e8186;
  --icon-info-color: #808080;
  --icon-image-color: white;
  --h2-text-color: #1a1a1a;
  --price-text-color: #2fa89e;
  --backgound-imgage: #3b9f93;
  --border-view-color: #a4d2c3;
}

/* all element */

#propertieslisting * {
  box-sizing: border-box;
  margin: 0;
}

#propertieslisting .propcards {
  /* grid-column: 2 / span 12;
  display: grid;
  grid-gap: 14%;
  position: relative; */
  width: calc(33.33% - 4px);
  margin-bottom: 20px;
  /* background-color: #f9f9f9; */
  padding: 15px;
  box-sizing: border-box;
}

/* reset element */

/* comman style */

#propertieslisting .img-overlay img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#propertieslisting .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#propertieslisting a {
  text-decoration: none;
  color: white;
}

#propertieslisting a:hover {
  color: white;
}

/* body style */

#propertieslisting body {
  font-family: "Open Sans", sans-serif;
  background-color: var(--bg-body);
}

/* container style */

#propertieslisting .propContainer {
  /* display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
  grid-gap: 5px;
  margin: 35px 0; */
  display: flex;
  flex-wrap: wrap;
  /* max-width: 1200px; */
  margin: 0 auto;
}

/* cards style */

#propertieslisting .cards::before {
  position: absolute;
  z-index: 5;
  color: white;
  background-color: var(--bg-featured);
  text-transform: capitalize;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  padding: 7px 7px;
}

/* card style */

#propertieslisting .card {
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: white;
  box-shadow: 0px 0px 7px 3px #dfdfdf;
}

#propertieslisting .card:hover {
  transform: translateY(-7px);
}

/* img-overlya style */

#propertieslisting .img-overlay {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

#propertieslisting .img-overlay img {
  width: 100%;
  z-index: 1;
}

#propertieslisting .img-overlay img:hover + div {
  width: 100%;
}

#propertieslisting figcaption {
  padding: 30px 0 30px 30px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--h2-text-color);
  font-size: 1.2rem;
}

#propertieslisting .overlay {
  width: 0;
  height: 100%;
  display: grid;
  place-content: center;
  background-color: var(--backgound-imgage);
  opacity: 0.8;
  z-index: 2;
  transition: all 0.5s ease 0.1s;
}

#propertieslisting .overlay:hover {
  width: 100%;
}

#propertieslisting .overlay:hover > a {
  display: block;
  text-align: center;
  border-color: var(--border-view-color);
}

#propertieslisting .overlay a {
  display: none;
  width: 140px;
  padding: 15px 0;
  text-transform: capitalize;
  border: 2px solid transparent;
  transition: border 10s ease;
}

/* icons-img style */

#propertieslisting .cont {
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 100%;
}

#propertieslisting .icons-img {
  position: relative;
  width: 100%;
  height: 100%;
}

#propertieslisting .icons-img button {
  position: absolute;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  top: -40px;
  z-index: 10;
}

#propertieslisting .icons-img button:first-of-type {
  right: 55px;
}

#propertieslisting .icons-img button:last-of-type {
  right: 20px;
}

#propertieslisting .icons-img button:first-of-type:hover {
  right: 55px;
  color: #ff3232;
}

#propertieslisting .icons-img i {
  font-size: 25px;
}

/* card-content styles */

#propertieslisting .card-content {
  padding: 0px 30px 30px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  font-size: 14px;
}

#propertieslisting .card-content p {
  color: var(--p-text-color);
  overflow: hidden;
}

/* icons-home style */

#propertieslisting .icons-home {
  display: flex;
  justify-content: space-between;
}

#propertieslisting .name-icon {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#propertieslisting .name-icon > span {
  text-transform: capitalize;
  color: var(--h2-text-color);
}

/* icon styles */

#propertieslisting .icon {
  display: flex;
  align-items: center;
}

#propertieslisting .icon i {
  color: var(--icon-info-color);
  font-size: 20px;
  margin-right: 10px;
}

#propertieslisting .icon span {
  vertical-align: middle;
}

/* price style */

#propertieslisting .price {
  /* text-transform: capitalize; */
  display: flex;
  flex-direction: column;
}

#propertieslisting .price span:last-of-type {
  color: var(--price-text-color);
  font-size: 18px;
}

/* media screen style */

@media screen and (max-width: 1000px) {
  #propertieslisting .card {
    grid-column-end: span 6;
  }
  #propertieslisting {
    flex-direction: column !important;
    width: 100%;
    margin: 0px !important;
    align-items: center;
  }
  #propertieslisting .propcards {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    /* background-color: #f9f9f9; */
    padding: 15px;
    box-sizing: border-box;
  }

  /* card-content style */
}

@media screen and (max-width: 700px) {
  /* #propertieslisting .cards {
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 30px;
  } */
  #propertieslisting .card {
    grid-column-end: span 12;
    margin-bottom: 25px;
  }
  /* icons-home style */
  #propertieslisting .card-content {
    font-size: 16px;
  }
  /* price style */
  #propertieslisting .price span:last-of-type {
    color: var(--price-text-color);
    font-size: 20px;
  }
  #propertieslisting .propContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  #propertieslisting .propcards {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    /* background-color: #f9f9f9; */
    padding: 15px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 500px) {
  /* #propertieslisting .cards {
    grid-template-columns: repeat(12, minmax(auto, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 30px;
    width: 250%;
  } */
  #propertieslisting .card {
    grid-column-end: span 12;
  }
  /* card-content style */
}

#propertieslisting {
  display: flex;
  flex-direction: row;
  margin: auto 70px;
}
