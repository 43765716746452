/* globale variable */

:root {
  --bg-body: #f4f4f4;
  --bg-hot: #dd3333;
  --bg-featured: #ea723d;
  --p-text-color: #7e8186;
  --icon-info-color: #808080;
  --icon-image-color: white;
  --h2-text-color: #1a1a1a;
  --price-text-color: #2fa89e;
  --backgound-imgage: #3b9f93;
  --border-view-color: #a4d2c3;
}

/* all element */

#propertiesxxx * {
  box-sizing: border-box;
  margin: 0;
}
#propertiesxxx {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: auto 70px;
}

#propertiesxxx .cards {
  grid-column: 2 / span 12;
  display: grid;
  grid-gap: 14%;
  position: relative;
}

#propertiesxxx .img-overlay img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#propertiesxxx .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#propertiesxxx a {
  text-decoration: none;
  color: white;
}

#propertiesxxx a:hover {
  color: white;
}

/* body style */

#propertiesxxx body {
  font-family: "Open Sans", sans-serif;
  background-color: var(--bg-body);
}

/* container style */

#propertiesxxx .container {
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
  grid-gap: 5px;
  margin: 35px 0;
}

/* cards style */

#propertiesxxx .cards::before {
  position: absolute;
  z-index: 5;
  color: white;
  background-color: var(--bg-featured);
  text-transform: capitalize;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  padding: 7px 7px;
}

/* card style */

#propertiesxxx .card {
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: white;
  box-shadow: 0px 0px 7px 3px #dfdfdf;
}

#propertiesxxx .card:hover {
  transform: translateY(-7px);
}

/* img-overlya style */

#propertiesxxx .img-overlay {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

#propertiesxxx .img-overlay img {
  width: 100%;
  z-index: 1;
}

#propertiesxxx .img-overlay img:hover + div {
  width: 100%;
}

#propertiesxxx figcaption {
  padding: 30px 0 30px 30px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--h2-text-color);
  font-size: 1.2rem;
}

#propertiesxxx .overlay {
  width: 0;
  height: 100%;
  display: grid;
  place-content: center;
  background-color: var(--backgound-imgage);
  opacity: 0.8;
  z-index: 2;
  transition: all 0.5s ease 0.1s;
}

#propertiesxxx .overlay:hover {
  width: 100%;
}

#propertiesxxx .overlay:hover > a {
  display: block;
  text-align: center;
  border-color: var(--border-view-color);
}

#propertiesxxx .overlay a {
  display: none;
  width: 140px;
  padding: 15px 0;
  text-transform: capitalize;
  border: 2px solid transparent;
  transition: border 10s ease;
}

/* icons-img style */

#propertiesxxx .cont {
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 100%;
}

#propertiesxxx .icons-img {
  position: relative;
  width: 100%;
  height: 100%;
}

#propertiesxxx .icons-img button {
  position: absolute;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  top: -40px;
  z-index: 10;
}

#propertiesxxx .icons-img button:first-of-type {
  right: 55px;
}

#propertiesxxx .icons-img button:last-of-type {
  right: 20px;
}

#propertiesxxx .icons-img button:first-of-type:hover {
  right: 55px;
  color: #ff3232;
}

#propertiesxxx .icons-img i {
  font-size: 25px;
}

/* card-content styles */

#propertiesxxx .card-content {
  padding: 0px 30px 30px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  font-size: 14px;
}

#propertiesxxx .card-content p {
  color: var(--p-text-color);
  overflow: hidden;
}

/* icons-home style */

#propertiesxxx .icons-home {
  display: flex;
  justify-content: space-between;
}

#propertiesxxx .name-icon {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#propertiesxxx .name-icon > span {
  text-transform: capitalize;
  color: var(--h2-text-color);
}

/* icon styles */

#propertiesxxx .icon {
  display: flex;
  align-items: center;
}

#propertiesxxx .icon i {
  color: var(--icon-info-color);
  font-size: 20px;
  margin-right: 10px;
}

#propertiesxxx .icon span {
  vertical-align: middle;
}

/* price style */

#propertiesxxx .price {
  /* text-transform: capitalize; */
  display: flex;
  flex-direction: column;
}

#propertiesxxx .price span:last-of-type {
  color: var(--price-text-color);
  font-size: 18px;
}

/* media screen style */

@media screen and (max-width: 1000px) {
  #propertiesxxx .card {
    grid-column-end: span 6;
  }
  #propertiesxxx {
    flex-direction: column !important;
    width: 55%;
    margin: 0px !important;
  }
  /* card-content style */
}

@media screen and (max-width: 700px) {
  #propertiesxxx .cards {
    grid-template-columns: repeat(12, minmax(auto, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 30px;
  }
  #propertiesxxx .card {
    grid-column-end: span 12;
  }
  /* icons-home style */
  #propertiesxxx .card-content {
    font-size: 16px;
  }
  /* price style */
  #propertiesxxx .price span:last-of-type {
    color: var(--price-text-color);
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  #propertiesxxx .container {
    grid-template-columns: 20px repeat(12, minmax(auto, 1fr)) 30px;
    grid-gap: 10px;
  }
  #propertiesxxx .cards {
    grid-template-columns: repeat(12, minmax(auto, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 30px;
    width: 250%;
  }
  #propertiesxxx .card {
    grid-column-end: span 12;
  }
  #propertiesxxx {
    grid-template-columns: auto;
  }
}
