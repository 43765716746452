@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700");

.faq * {
  font-family: Lato, sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.faq {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 1000px;
  width: 100%;
  background: url("cnc.jpg") no-repeat center center;
  background-size: cover;
}

.faq .faqwrapper {
  width: 60%;
  opacity: 0.9;
}

.faq h1 {
  margin-bottom: 20px;
  color: white;
}

.faq .faqcontainer {
  background-color: white;
  color: black;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgb(0, 0, 0, 0.25);
  margin: 20px 0;
}

.faq .faqquestion {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 20px 80px 20px 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.faq .faqquestion::after {
  content: "\002B";
  font-size: 2.2rem;
  position: absolute;
  right: 20px;
  transition: 0.3s;
}

.faq .faqquestion.active::after {
  transform: rotate(45deg);
}

.faq .faqanswercont {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}

.faq .faqanswer {
  padding: 0 20px 20px;
  line-height: 1.5rem;
}

.faq .faqquestion.active + .faqanswercont {
}

@media screen and (max-width: 790px) {
  .faq html {
    font-size: 14px;
  }
  .faq .faqwrapper {
    width: 80%;
  }
}
