.titleContainer {
  margin-top: 0px;
}
.titleContainer h1 {
  margin-top: 85px;
}
@media only screen and (max-width: 769px) {
  .title {
    font-size: 43px !important;
    font-weight: 100;
  }
  .titleContainer h1 {
    margin-top: 110px;
  }
}
