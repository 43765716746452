#refdetail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(background.jpg);
  background-size: cover;
}

.refcontainer-detail {
  background-color: #f4f8fc;
  margin-top: 193px;
  margin-bottom: 140px;
  max-width: 900px;
}
.refdetails {
  align-items: center;
  justify-content: center;
}
.detail-top-title {
  font-weight: 600;
  text-transform: capitalize;
  color: black;
  font-size: 2rem;
  padding: 20px;
}
#refslider {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper {
  width: 100%;
}
.rowslider {
  width: 100%;
}
/* .slidercont {
  width: 100%;
} */
.refproduct-images-slider {
  box-sizing: border-box;
  margin-top: 10px;
  height: 18%;
  margin-bottom: 70px;
}
.refproduct-images-slider .swiper-slide {
  width: 25%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-images-slider .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-button-prev,
.swiper-button-next {
  font-size: 10px;
  color: rgb(255, 255, 255);
}
.swiper-button-prev::after,
.swiper-button-next::after {
  padding: 10px;
}
.swiper {
  width: 100%;
}
