/* WhatsAppButton.css */

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.whatsapp-icon:hover {
  background-color: #128c7e;
}

.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}

/* Whatsapp icon css */
.whats-float {
  position: fixed;
  transform: translate(108px, 0px);
  bottom: 12%;
  right: 0;
  width: 150px;
  overflow: hidden;
  background-color: #25d366;
  color: #fff;
  border-radius: 4px 0 0 4px;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  vertical-align: middle;
}
.whats-float a span {
  color: white;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 10px;
  position: absolute;
  line-height: 16px;
  font-weight: bolder;
}

.whats-float i {
  font-size: 30px;
  color: white;
  line-height: 30px;
  padding: 10px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  text-align: center;
}

.whats-float:hover {
  color: #ffffff;
  transform: translate(0px, 0px);
}

.whats-float:hover i {
  transform: rotate(360deg);
}

/* Sahibinden icon css */
.sahibinden-float {
  position: fixed;
  transform: translate(108px, 0px);
  bottom: 3%;
  right: 0;
  width: 150px;
  overflow: hidden;
  background-color: #f7e100;
  color: #fff;
  border-radius: 4px 0 0 4px;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  vertical-align: middle;
}

.sahibinden-float a span {
  color: black;
  font-size: 12px;
  padding-top: 17px;
  position: absolute;
  line-height: 16px;
  font-weight: bolder;
}

.sahibinden-float i {
  font-size: 30px;
  color: black;
  line-height: 30px;
  padding: 10px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  text-align: center;
}

.sahibinden-float:hover {
  color: #ffffff;
  transform: translate(0px, 0px);
}

.sahibinden-float:hover i {
  transform: rotate(360deg);
}
