@import url("https://fonts.googleapis.com/css2?family=Signika:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root {
  --primary-color: #3655d2;
  --secondary-color: #d3c4ad;
  --accent-color: #22537;
  --black: #333333;
  --grey: #dddddd;
  --white: #ffffff;
  --text: #463d3d;
  --pri-font: "Signika", sans-serif;
  --sec-font: "Roboto", sans-serif;
}

body {
  margin: 0;
}

.solution {
  display: flex;
  justify-content: center;
}

.inner-solution {
  width: 90%;
}

.sec-4-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 40px;
  gap: 40px;
}

.sec-4-grid-box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.sec-4-grid-box h4 {
  padding: 20px;
  background-color: var(--white);
  color: var(--text);
  font-family: var(--pri-font);
  font-weight: 400;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: -40px 20px 0;
  z-index: 2;
  position: sticky;
}

@media screen and (max-width: 600px) {
  .sec-4-grid-row {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 40px;
    gap: 40px;
    padding: 20px;
  }

  .sec-4-bg-img {
    padding: 20px 0 40px;
  }

  .sec-4-heading {
    margin: 20px 0;
  }
}


.sec-4-heading {
  font-size: 40px;
  color: #50A596 !important;
  font-family: var(--pri-font);
  font-weight: 500;
  margin: 0px;
  padding: 0 20px;
}