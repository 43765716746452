.search-container {
  display: flex;
  width: 80%;
  margin: 0 auto;
  height: 100px;
  background-color: white;
  margin-top: 500px;
  border-radius: 60px;
}
.hemenara a {
  text-decoration: none;
  color: white;
}

@media screen and (min-width: 1600px) {
  .search-container {
    margin-top: 650px;
  }
}
@media screen and (max-width: 375px) {
  .search-container {
    margin-top: 300px !important;
  }
}
.search-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 48px;
  z-index: 9999;

  .form-select {
    width: 250px;
    height: 50px;
  }
}

@media screen and (max-width: 768px) and (min-width: 375px) {
  .search-in {
    flex-direction: column;
    padding: 20px;
  }
  .search-container {
    height: 350px;
    margin-top: 450px;
  }

  .srcin {
    width: 83%;
    margin-top: 20px;
  }

  .hemenara {
    margin-top: 10px;
  }
}

.srcin {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  height: 50px;
}

.srcin::placeholder {
  color: #000; /* İstediğiniz renge uygun bir değer seçin */
  opacity: 1; /* Ya da istediğiniz bir değer */
}

.hemenara {
  color: white;
  background-color: #50a596 !important;
  border-radius: 50px !important;
  height: 50px;
  width: 150px;
}
